import React from 'react';

import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout language="de" slug="/404">
    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
      <div>
        <h1>Die Seite konnte leider nicht gefunden werden</h1>
        <p>Die von Ihnen angeforderte Seite existiert nicht. Besuchen Sie gerne unsere Startseite.</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;